.logoWrp {
  width: 100%;
  height: var(--top-header-height);
  min-height: var(--top-header-height);
  border-bottom: 1px solid var(--global-border);
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  justify-content: space-between;
}
.brand {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.brand img {
  width: 35px;
  height: 35px;
}
.title {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

/* --sidebar-width */
