.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: var(--global-background);
  overflow: hidden;
}
.sidebarWrp {
  height: 100%;
  width: var(--sidebar-width);
  background-color: var(--global-surface);
  display: flex;
  flex-direction: column;
}
.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top {
  height: var(--top-header-height);
  min-height: var(--top-header-height);
  width: 100%;
  display: flex;
  background-color: var(--blue-secondary);
  border-bottom: 1px solid var(--global-border);
  padding: 8px 16px;
}
.children {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
