.sidebarBody {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 10px 0px;
}
.scroll {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 0px;
}
/* each menu*/
.menu {
  width: 100%;
  height: 40px;
  display: flex;
  gap: 10px;
  cursor: pointer;
  padding: 0px 10px;
  align-items: center;
}
.menu span {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 10px;
  cursor: pointer;
  padding: 5px 10px;
  align-items: center;
  border-radius: 7px;
}
.selected {
  background-color: var(--blue-primary);
  color: var(--global-white);
  box-shadow: 0 0 5px #000000;
}
.menuFooter {
  width: 100%;
  height: 80px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.logoutButtonAlt {
  text-align: center;
}
