.container {
  padding: 7px 10px;
  border-radius: 7px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--blue-primary);
  cursor: pointer;
}
.text {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--blue-primary);
}
.container:not(.disable):hover {
  background-color: var(--blue-secondary);
}
.disable {
  cursor: not-allowed;
  opacity: 0.3;
}
