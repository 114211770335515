.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
/* page list header */
.pageHeaderContainer {
  width: 100%;
  height: 65px;
  max-height: 65px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

/* table */
.tableContainer {
  padding: 10px;
  border: 1px solid var(--global-border2);
  border-radius: 7px;
}
.tr {
  height: 45px;
  border-bottom: 1px solid black;
}

/* share */
.shareContainer {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  gap: 20px;
}
.shareTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eachShareTab {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 10px;
}
.selectedShareTab {
  border-bottom: 1px solid var(--green-secondary);
}

/* add chatbot list */
.addConversationFormContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.eachInput {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
